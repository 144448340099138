<section fxLayout="column" fxLayoutAlign="start center">
  <app-banner [text]="banner.LOGIN" *ngIf="!paramsMissing"></app-banner>
  <app-banner [text]="banner.MISSING_PARAM" *ngIf="paramsMissing"></app-banner>
  <section class="signin-btn-container" *ngIf="!paramsMissing">
    <section class="login-section">
      <div class="sign-in-button">
        <ao-google-login
          [auto]="autoEnabled"
          [clientId]="clientId"
          [scopes]="scopes"
          (loginSuccess)="googleLoginSuccess($event)"
          (loginError)="loginError()"
          [disabled]="googleLoading"
          display-mode="content"
          authentication-mode="token"
          id="gLogin">
          <img alt='' *ngIf="!googleLoading"
               width=""
               class="button-header"
               (click)="googleAuth()"
               src="assets/img/btn_google_signin_light_normal_web.png">
          <mat-progress-spinner *ngIf="googleLoading" mode="indeterminate" [diameter]="45"></mat-progress-spinner>
        </ao-google-login>
      </div>
    </section>
  </section>
</section>
