import {Component, OnInit} from '@angular/core';
import {TrainingService} from '../../services/training.service';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {Banner} from '../../constants/constant-text';

@Component({
  selector: 'app-entry-point',
  templateUrl: './entry-point.component.html',
  styleUrls: ['./entry-point.component.scss']
})
export class EntryPointComponent implements OnInit {

  public bannerMessage = Banner.LOADING_SCREEN;
  public loading = true;

  constructor(private trainingService: TrainingService,
              private dataService: DataService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.redirect();
  }

  redirect(): void {
    this.trainingService.questionsOrResult(this.dataService.domain,
      this.dataService.documentId,
      this.dataService.qaId,
      this.dataService.authUser).subscribe({
      next: async resp => {
        if (resp.questions) {
          this.dataService.questions = resp.questions;
          await this.router.navigate(['quiz']);
        } else if (resp.result) {
          this.dataService.result = resp.result;
          await this.router.navigate(['result']);
        } else {
          await this.router.navigate(['login']);
        }
      },
      error: error => {
        console.error(error);
        this.loading = false;
        this.bannerMessage = Banner.ERROR_MESSAGE;
      }
    });
  }
}
