import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  public text$: string[];

  constructor() {
  }

  ngOnInit(): void {
  }

  @Input() set text(value: string[]) {
    this.text$ = value;
  }

  get text(): string[] {
    return this.text$;
  }


}
