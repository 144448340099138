<section fxLayout="column" fxLayoutAlign="start center">
  <app-banner [text]="banner.QUIZ" [hidden]="(loading$ | async) || error"></app-banner>
  <app-banner [text]="banner.PROCESSING_ANSWERS" *ngIf="loading$ | async"></app-banner>
  <app-banner [text]="banner.ERROR_SUBMIT" *ngIf="error"></app-banner>
  <app-questions *ngIf="questions" [questions]="questions" (submitEvent)="submit($event)" class="questions-comp"
                 [hidden]="(loading$ | async) || error"></app-questions>
  <section id="submit-spinner" class="spinner-container" *ngIf="loading$ | async">
    <mat-spinner [diameter]="50"></mat-spinner>
  </section>
</section>


