<section class="main-container">
  <section class="question-container" fxLayout="row" fxLayoutAlign="start">
    <section class="number-container" fxLayoutAlign="start center">
      <p>{{index+1}}</p>
    </section>
    <section fxLayoutAlign="start center" class="question-text-container">
      <p>{{question.question}}</p>
    </section>
  </section>
  <section class="choice-container">
    <mat-radio-group class="question-radio-group" [(ngModel)]="question.userAnswer" required>
      <mat-radio-button class="question-radio-button" *ngFor="let choice of question.choices" [value]="choice">
        <span class="choice-text">{{choice}}</span>
      </mat-radio-button>
    </mat-radio-group>
  </section>
</section>
