import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Question} from '../../models/question.model';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  private questions$: Question[];
  @Output() submitEvent = new EventEmitter<Question[]>();

  constructor() {
  }

  ngOnInit(): void {
  }

  @Input() set questions(value) {
    this.questions$ = value;

  }

  get questions(): Question[] {
    return this.questions$;
  }

  disable(): boolean {
    return this.questions.map(question => question.userAnswer).filter(value => value === undefined).length > 0;
  }

  submit(): void {
    this.submitEvent.emit(this.questions);
  }

}
