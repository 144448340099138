<section class="main-container">
  <section class="header" fxLayout="row" fxLayoutAlign="space-between start">
    <section fxFlex="88">
      <h2 fxFlex="70">Please answer the questions below and hit the submit button</h2>
    </section>
    <section fxFlex="12" class="icon-container">
      <img alt="aodocs" src="assets/img/icon_form.svg">
    </section>
  </section>
  <section class="questions">
    <ng-container *ngFor="let question of questions; index as i">
      <app-question [question]="question" [index]="i"></app-question>
    </ng-container>
  </section>
  <section class="button-container" fxLayoutAlign="center">
    <button mat-flat-button (click)="submit()" [disabled]="disable()">SUBMIT</button>
  </section>
</section>
