import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {first, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authErrorMessage$ = new Subject<string>();
  private token: string;
  profile: any;

  constructor(private router: Router,
              private http: HttpClient) {
  }

  async signInWithGoogle(authDetails: any) {
    this.token = authDetails.accessToken;
    this.retrieveGoogleUserInfo().pipe(
      tap((user) => (this.setProfile(user))),
      first()
    )
      .subscribe({
        next: () => this.router.navigate(['entry']),
        error: async (e: HttpErrorResponse) => {
          this.setToken(null);
          this.authErrorMessage$.next(e.error);
          await this.router.navigate(['login']);
        },
      });
  }

  retrieveGoogleUserInfo(): Observable<any> {
    return this.http.get<any>('https://openidconnect.googleapis.com/v1/userinfo');
  }

  setProfile(profile: any): void {
    if (profile) {
      this.profile = profile;
    } else {
      this.profile = null;
      this.router.navigate(['login']);
    }
  }

  setToken(token: string): void {
    this.token = token;
  }

  getToken(): string {
    return this.token;
  }

  isLoggedIn(): boolean {
    return !!this.token;
  }
}
