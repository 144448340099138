import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {QuizComponent} from './pages/quiz/quiz.component';
import {ResultComponent} from './pages/result/result.component';
import {AuthGuardService} from './services/guard/auth-guard.service';
import {LoginComponent} from './pages/login/login.component';
import {EntryPointComponent} from './pages/entry-point/entry-point.component';
import {TechnicalSignInComponent} from './pages/technical-sign-in/technical-sign-in.component';

const routes: Routes = [
  {
    path: 'quiz',
    component: QuizComponent,
    canActivate: [AuthGuardService]

  },
  {
    path: 'result',
    component: ResultComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'entry',
    component: EntryPointComponent,
    canActivate: [AuthGuardService]

  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'technical-sign-in',
    component: TechnicalSignInComponent
  },
  {
    path: '', redirectTo: 'login', pathMatch: 'full'

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
