import {Component, Input, OnInit} from '@angular/core';
import {Question} from '../../../models/question.model';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  private question$: Question;
  @Input() index: number;

  constructor() {
  }

  ngOnInit(): void {
  }

  @Input() set question(value) {
    this.question$ = value;
  }

  get question(): Question {
    return this.question$;

  }

}
