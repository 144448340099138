export let Banner = {
  QUIZ: ['You have completed the first part of your training', 'Time to check what you have learned !'],
  RESULT_PASSED: ['Congratulations, you have passed.', ''],
  RESULT_FAILED: ['Your score is below. You need to get {threshold} correct to pass.', 'Please try again.'],
  LOGIN: ['You need to sign-in in order to complete the training !'],
  MISSING_PARAM: ['Some parameters are missing.', 'Please contact us to resolve this issue !'],
  LOADING_SCREEN: ['We are preparing your training', 'Please wait...'],
  PROCESSING_ANSWERS: ['We are processing your answers', 'Please wait...'],
  ERROR_MESSAGE: ['Something went wrong while trying to retrieve the data', 'Please try again later !'],
  ERROR_SUBMIT: ['Something went wrong while submitting your answers', 'Please try again later !']
};

