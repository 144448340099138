  <section class="login-section">
      <h3>Store your credentials</h3>
      <section>
        <form [formGroup]="form">
          <div class="form-group">
            <input
              formControlName="domain"
              type="text"
              class="form-control domain-input"
              id="domain"
              aria-describedby="domainHelp"
              placeholder="Enter Domain"
              required
            />
          </div>
          <div class="form-group">
            <input
              formControlName="secret"
              type="text"
              class="form-control domain-input"
              id="secret"
              aria-describedby="secretHelp"
              placeholder="Enter Secret key"
              required
            />
          </div>
        </form>
      </section>
      <img alt=''
           width=""
           class="sign-in-btn"
           (click)="login()"
           src="assets/img/btn_google_signin_dark_normal_web@2x.png">
    </section>
