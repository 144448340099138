import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-technical-sign-in',
  templateUrl: './technical-sign-in.component.html',
  styleUrls: ['./technical-sign-in.component.scss']
})
export class TechnicalSignInComponent implements OnInit {
  form = this.fb.group({
    domain: ['', Validators.required],
    secret: ['', Validators.required]
  });
  quizUrl = environment.baseUrl

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
  }

  login(): void {
    if (this.form.valid) {
      const url = this.quizUrl + '/login?domain=' + this.domain + '&secretKey=' + this.secretKey;
      window.open(url,'_self');
    }
  }

  get domain() {
    return this.form.get('domain').value;
  }

  get secretKey() {
    return this.form.get('secret').value;
  }

  set domain(value: string) {
    this.form.get('domain').setValue(value);
  }

}
