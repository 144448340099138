import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Banner} from '../../constants/constant-text';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../services/data.service';
import {Scope} from '../../constants/scopes';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  autoEnabled = false;
  googleLoading = false;
  scopes = [Scope.EMAIL, Scope.PROFILE, Scope.OPENID].join(' ');
  clientId = environment.clientId;
  public banner = Banner;
  public paramsMissing = false;

  constructor(private authService: AuthService,
              private dataService: DataService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.saveQueryParams()) {
      this.autoEnabled = true;
    } else {
      this.paramsMissing = true;
    }
  }

  saveQueryParams(): boolean {
    this.dataService.domain = this.route.snapshot.queryParams.domain;
    this.dataService.documentId = this.route.snapshot.queryParams.id;
    this.dataService.qaId = this.route.snapshot.queryParams.qaId;
    this.dataService.authUser = this.route.snapshot.queryParams.authUser;
    return !!this.dataService.documentId && !!this.dataService.qaId && !!this.dataService.authUser && !!this.dataService.domain;
  }

  googleLoginSuccess(event: any): void {
    try {
      this.authService.signInWithGoogle(event.detail);
    } catch (e: any) {
      this.googleLoading = false;
      this.authService.authErrorMessage$.next(e.error);
    }
  }

  loginError(): void {
    this.googleLoading = false;
    this.authService.authErrorMessage$.next('Error: An error occurred during authentication');
  }

  googleAuth(): void {
    setTimeout(() => {
      this.googleLoading = true;
    });
  }

}
