import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {QuestionsComponent} from './components/questions/questions.component';
import {ResultViewerComponent} from './components/result-viewer/result-viewer.component';
import {QuizComponent} from './pages/quiz/quiz.component';
import {BannerComponent} from './components/banner/banner.component';
import {ResultComponent} from './pages/result/result.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatIconModule} from '@angular/material/icon';
import {FlexModule} from '@angular/flex-layout';
import {QuestionComponent} from './components/questions/question/question.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FooterComponent} from './components/footer/footer.component';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {LoginComponent} from './pages/login/login.component';
import {EntryPointComponent} from './pages/entry-point/entry-point.component';
import '@aodocs/authentication';
import {AuthTokenInterceptor} from './interceptors/request.interceptor';
import {TechnicalSignInComponent} from './pages/technical-sign-in/technical-sign-in.component';

@NgModule({
  declarations: [
    AppComponent,
    QuizComponent,
    BannerComponent,
    ResultComponent,
    QuestionsComponent,
    ResultViewerComponent,
    QuestionComponent,
    FooterComponent,
    LoginComponent,
    TechnicalSignInComponent,
    EntryPointComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    FlexModule,
    FormsModule,
    MatRadioModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    NgCircleProgressModule.forRoot({
      backgroundPadding: 0,
      radius: 45,
      space: -7,
      maxPercent: 100,
      outerStrokeWidth: 7,
      outerStrokeColor: '#6FBA11',
      outerStrokeLinecap: 'square',
      innerStrokeColor: '#D8D8D8',
      innerStrokeWidth: 7,
      titleColor: '#6FBA11',
      titleFontSize: '30',
      imageHeight: 127,
      animateTitle: false,
      animationDuration: 1000,
      showTitle: true,
      showUnits: true,
      unitsColor: '#6FBA11',
      unitsFontSize: '30',
      showSubtitle: false,
      responsive: true
    }),
    ReactiveFormsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthTokenInterceptor,
    multi: true
  }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}
