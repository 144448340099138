import {Injectable} from '@angular/core';
import {Question} from '../models/question.model';
import {Result} from '../models/result.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private documentId$: string;
  private questions$: Question[];
  private result$: Result;
  private qaId$: string;
  private domain$: string;
  private authUser$: string;

  constructor() {
  }

  set domain(value: string) {
    this.domain$ = value;
  }

  get domain(): string {
    return this.domain$;
  }

  set documentId(value: string) {
    this.documentId$ = value;
  }

  get documentId(): string {
    return this.documentId$;
  }

  set qaId(value: string) {
    this.qaId$ = value;
  }

  get qaId(): string {
    return this.qaId$;
  }

  set questions(value: Question[]) {
    this.questions$ = value;
  }

  get questions(): Question[] {
    return this.questions$;
  }

  set result(value: Result) {
    this.result$ = value;
  }

  get result(): Result {
    return this.result$;
  }

  set authUser(value: string) {
    this.authUser$ = value;
  }

  get authUser(): string {
    return this.authUser$;
  }
}
