import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {Result} from '../../models/result.model';

@Component({
  selector: 'app-result-viewer',
  templateUrl: './result-viewer.component.html',
  styleUrls: ['./result-viewer.component.scss']
})
export class ResultViewerComponent implements OnInit {

  private result: Result;
  public percentage: number;
  public totalQuestions: number;
  public correctAnswers: number;

  constructor(private dataService: DataService) {
    this.result = this.dataService.result;
    this.correctAnswers = this.result.correctAnswers;
    this.totalQuestions = this.result.totalQuestions;
    this.percentage = this.correctAnswers * 100 / this.totalQuestions;

  }

  ngOnInit(): void {
  }

}
