import {Component, OnInit} from '@angular/core';
import {Banner} from '../../constants/constant-text';
import {Question} from '../../models/question.model';
import {Router} from '@angular/router';
import {TrainingService} from '../../services/training.service';
import {DataService} from '../../services/data.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  private questions$: Question[];
  public banner = Banner;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public error = false;

  constructor(private dataService: DataService,
              private trainingService: TrainingService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.questions = this.dataService.questions;
    let i = 1;
    this.questions.forEach(question => {
      question.index = i;
      i++;
    });
  }

  submit(answers: Question[]): void {
    this.loadingSubject.next(true);
    this.trainingService.submit(answers,
      this.dataService.domain,
      this.dataService.documentId,
      this.dataService.qaId,
      this.dataService.authUser).subscribe({
      next: async resp => {
        this.dataService.result = resp;
        this.loadingSubject.next(false);
        await this.router.navigate(['result']);
      },
      error: error => {
        console.error(error);
        this.loadingSubject.next(false);
        this.error = true;
      }
    });
  }

  get questions(): Question[] {
    return this.questions$;
  }

  set questions(questions) {
    this.questions$ = questions;
  }

}
