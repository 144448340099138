import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Apis, ApiVersion} from '../constants/apis';
import {QuestionsResult} from '../models/questions-result.model';
import {Question} from '../models/question.model';
import {Result} from '../models/result.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  private apiUrl = environment.apiUrl;
  private baseUrl = environment.baseUrl;


  constructor(private http: HttpClient) {
  }

  questionsOrResult(domain: string, documentId: string, qaId: string, authUser: string): Observable<QuestionsResult> {
    const url = this.toUrl(Apis.TRAINING, 'questionsOrResult', ApiVersion.V1);
    const params = {domain, documentId, qaId, authUser, questions: true};
    return this.http.get<QuestionsResult>(url, {params});
  }

  submit(questions: Question[], domain: string, documentId: string, qaId: string, authUser: string): Observable<Result> {
    const url = this.toUrl(Apis.TRAINING, 'submit', ApiVersion.V1);
    const params = {domain, documentId, qaId, authUser};
    return this.http.post<Result>(url, {questions}, {params});
  }


  protected toUrl(root: string, method: string, version: string): string {
    return this.baseUrl + this.apiUrl + root + (method ? '/' + version + '/' + method : '/' + version);
  }
}


