import {Component, OnInit} from '@angular/core';
import {Banner} from '../../constants/constant-text';
import {DataService} from '../../services/data.service';
import {LocationStrategy} from '@angular/common';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  public banner = Banner;
  public passed: boolean;
  public resultFailedMessage;
  public passedReview = false;

  constructor(private dataService: DataService,
              private locationStrategy: LocationStrategy) {
    this.preventBackButton();
  }

  ngOnInit(): void {
    if (this.dataService.result.correctAnswers < this.dataService.result.threshold) {
      this.failed();
    } else {
      this.succeeded();
    }
  }

  result_failed(): string[] {
    return Array.of(Banner.RESULT_FAILED[0].replace('{threshold}', this.dataService.result.threshold.toString()), Banner.RESULT_FAILED[1]);
  }

  failed(): void {
    this.resultFailedMessage = this.result_failed();
    this.passed = false;
  }

  succeeded(): void {
    this.passed = true;
    if (this.dataService.result.passed) {
      this.passedReview = true;
    }
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }
}
